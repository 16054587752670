import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { DriverCard } from '../../components/driver_card.component';
import { MYCSearch, MYCSelect } from '../../components/input_form.component';
import { useGet, useGetPaginated } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { MYCPagination } from '../../components/table.component';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';

const DRIVERS = [
    {
        img: 'https://i.pravatar.cc/100',
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: 'https://i.pravatar.cc/100',
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: 'https://i.pravatar.cc/100',
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: 'https://i.pravatar.cc/100',
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
]

export const DriversScreen = () => {
    const [query, setQuery] = useState({});
    const [form] = useForm();
    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const navigate = useNavigate();
    const driversRq = useGetPaginated(Endpoints.ALL_DRIVERS_TRIP_COUNT, true, query);
    return <>
        <span className='self-center text-lg'>Drivers List</span>
        <div className="self-center w-2/3 my-5">
            <div className="flex flex-row gap-5  justify-between">
                <Form form={form} onFinish={setQuery} layout="vertical">
                    <div className="flex items-end flex-row gap-5">
                        <MYCSearch name="driverName" className="flex-1" label="Search By Driver Name" />
                        <MYCSelect className="flex-1" options={
                            originsRq?.data?.map?.(M => ({ value: M.id, label: M.name }))
                        } name="originId" label={"Region"} required />
                        <Button className="px-5" loading={driversRq?.loading} htmlType="submit" type="primary">Search</Button>
                    </div>
                </Form>
                <Button onClick={navigate?.bind(this,'/create-driver')} className="self-end px-10" type="primary">Create Driver</Button>
            </div>
        </div>

        <div className="flex flex-col gap-5">
            {driversRq?.list?.map(D => <DriverCard key={driversRq?.driver?.id} refresh={driversRq?.refresh} driver={D?.driver} totalTripCount = {D?.totalTripsCompleted} />)}
        </div>
        <MYCPagination {...driversRq} />
    </>
}